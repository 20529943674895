
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Row,
  Button,
  Field,
  NavBar,
  Image as VanImage,
  Icon,
  Popup,
  Picker,
  Form,
} from "vant";
import ItemTitle from "@/views/Mall/Payment/ItemTitle.vue";

declare type Column = {
  id: string;
  text: string;
  children?: Array<Column>;
};

let formInfo: order.InvoiceConfirmReq = {
  addrDisProvId: "",
  addrDispCityId: "",
  addrDispCounId: "",
  addrDitailAddr: "",
  addrMobile: "",
  emailAddress: "",
  consignee: "",
  dutyParagraph: "",
  invoiceAmt: 0,
  invoiceContent: "*生活服务*演出票款",
  invoiceTitle: "",
  invoiceType: "ENTERPRISE",
  orderId: "",
  remarks: "",
  orderType: "ORDER_TICKET",
};

@Component({
  components: {
    Mixin,
    [Row.name]: Row,
    [Button.name]: Button,
    [Field.name]: Field,
    [NavBar.name]: NavBar,
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Form.name]: Form,
    ItemTitle,
  },
})
export default class Invoice extends Mixins(Mixin) {
  form = { ...formInfo };
  selected = 0;
  InvoiceTypes = [
    { name: "企业单位", id: "ENTERPRISE" },
    { name: "个人/非企业单位", id: "PERSONAL" },
  ];
  columns: Array<Column> = [];
  showAddress = false;
  addressName = "";
  orderId = "";
  orderType: "ORDER_TICKET" | "ORDER_GOOD" | "ORDER_VIDEO" = "ORDER_TICKET";
  invoiceAmt = "";
  mounted(): void {
    this.orderId = this.$route.params.orderId || "";
    let type = this.$route.params.type || "1";
    if (type === "1") {
      this.orderType = "ORDER_TICKET";
    } else if (type === "2") {
      this.orderType = "ORDER_GOOD";
    } else if (type === "3") {
      this.orderType = "ORDER_VIDEO";
    }
    this.getRegions();
    this.getEnterprise();
  }

  onClickLeft(): void {
    this.$router.go(-1);
  }

  selectType(item: { name: string; id: string }, index: number): void {
    this.form.invoiceType = item.id as "ENTERPRISE" | "PERSONAL" | undefined;
    this.selected = index;
    if (item.id == "ENTERPRISE") {
      this.getEnterprise();
    } else {
      this.getPersonal();
    }
  }
  /*
   * 获取企业信息
   */
  getEnterprise(): void {
    this.$api.orderApi.orderInvoice.getEnterpriseInvoiceAdvance(
      this.orderId,
      this.orderType,
      ({ data }) => {
        this.form.invoiceTitle = data.invoiceTitle || "";
        this.form.invoiceAmt = data.invoiceAmt;
        this.invoiceAmt = data.invoiceAmt
          ? Number(data.invoiceAmt).toFixed(2)
          : "0";
        this.form.dutyParagraph = data.dutyParagraph || "";
        this.form.invoiceContent = data.invoiceContent || "";
        this.form.invoiceType = data.invoiceType || this.form.invoiceType;
        this.form.addrDisProvId = data.addrDisProvId || "";
        this.form.addrDispCityId = data.addrDispCityId || "";
        this.form.addrDispCounId = data.addrDispCounId || "";
        this.form.addrDitailAddr = data.addrDitailAddr || "";
        this.form.addrMobile = data.addrMobile || "";
        this.form.consignee = data.consignee || "";
        this.form.remarks = data.remarks || "";
        this.form.orderId = data.orderId || "";
        this.form.orderType = data.orderType || this.orderType;
        if (
          data.addrDisProName &&
          data.addrDispCityName &&
          data.addrDispCounName
        ) {
          this.addressName =
            data.addrDisProName +
            "/" +
            data.addrDispCityName +
            "/" +
            data.addrDispCounName;
        } else {
          this.addressName = "";
        }
      }
    );
  }
  /*
   * 获取个人信息
   */
  getPersonal(): void {
    this.$api.orderApi.orderInvoice.getPersonalInvoiceAdvance(
      this.orderId,
      this.orderType,
      ({ data }) => {
        this.form.invoiceTitle = "";
        this.form.dutyParagraph = "";
        this.form.invoiceAmt = data.invoiceAmt;
        this.invoiceAmt = data.invoiceAmt
          ? Number(data.invoiceAmt).toFixed(2)
          : "0";
        this.form.invoiceContent = data.invoiceContent || "";
        this.form.invoiceType = data.invoiceType || this.form.invoiceType;
        this.form.addrDisProvId = data.addrDisProvId || "";
        this.form.addrDispCityId = data.addrDispCityId || "";
        this.form.addrDispCounId = data.addrDispCounId || "";
        this.form.addrDitailAddr = data.addrDitailAddr || "";
        this.form.addrMobile = data.addrMobile || "";
        this.form.consignee = data.consignee || "";
        this.form.remarks = data.remarks || "";
        this.form.orderId = data.orderId || "";
        this.form.orderType = data.orderType || this.orderType;
        if (
          data.addrDisProName &&
          data.addrDispCityName &&
          data.addrDispCounName
        ) {
          this.addressName =
            data.addrDisProName +
            "/" +
            data.addrDispCityName +
            "/" +
            data.addrDispCounName;
        } else {
          this.addressName = "";
        }
      }
    );
  }
  /*
   * 选择地区接口
   */
  getRegions(): void {
    this.InterfaceCacheModule.getData({
      cacheKey: "region.getRegionList",
      apiMethod: this.$api.dictionaryApi.region.getRegionList,
      callback: ({ data }) => {
        this.columns = this.getColumns(data);
      },
    });
  }
  /*
   * 处理地区接口结构
   */
  getColumns(regions?: Array<dictionary.RegionDto>): Array<Column> {
    if (!regions || regions.length < 1) {
      return [];
    }
    let columns: Array<Column> = [];
    regions.forEach((region) => {
      let children = this.getColumns(region.regionDtosList);
      if (children && children.length > 0) {
        columns.push({
          id: region.regionId || "",
          text: region.regionName || "",
          children,
        });
      } else {
        columns.push({
          id: region.regionId || "",
          text: region.regionName || "",
        });
      }
    });
    return columns;
  }
  /*
   * 获取地区省市县对应id
   */
  selectRegion(picker: Array<string>, arr: Array<number>): void {
    this.showAddress = false;

    this.addressName = picker.join().replace(new RegExp(/,/g), "/");
    let index1 = arr[0]; // 第一个数组索引
    let index2 = arr[1]; // 第二个数组索引
    let index3 = arr[2]; // 第三个数组索引
    let province = this.columns[index1]; // 省份
    this.form.addrDisProvId = province.id;
    let cities = province.children; // 该省份下的所有地级市
    // // 比较一下 index2 防止数组越界
    if (cities && cities.length > index2) {
      let city = cities[index2]; // 选中的地级市
      this.form.addrDispCityId = city.id;
      let countries = city.children; // 该地级市下边的所有乡镇
      // 比较一下 index3 防止数组越界
      if (countries && countries.length > index3) {
        this.form.addrDispCounId = countries[index3].id;
      }
    }
  }

  formValidation(): boolean {
    let reg = /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/;
    let phone =
      /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
    if (this.form.invoiceTitle === "") {
      this.$toast("请填写发票抬头");
      return false;
    } else if (
      this.form.dutyParagraph === "" &&
      this.form.invoiceType === "ENTERPRISE"
    ) {
      this.$toast("请填写纳税人识别号");
      return false;
    } else if (
      this.form.dutyParagraph &&
      !reg.test(this.form.dutyParagraph) &&
      this.form.invoiceType === "ENTERPRISE"
    ) {
      this.$toast("请填写正确的纳税人识别号");
      return false;
    } else if (this.form.invoiceAmt === 0) {
      this.$toast("发票金额为0，无法提交");
      return false;
    } else if (this.form.consignee === "") {
      this.$toast("请填写收件人");
      return false;
    } else if (this.form.addrMobile === "") {
      this.$toast("请填写收件人联系方式");
      return false;
    } else if (this.form.addrMobile && !phone.test(this.form.addrMobile)) {
      this.$toast("请填写正确的联系方式");
      return false;
    } else if (this.addressName === "") {
      this.$toast("请选择所在地区");
      return false;
    } else if (this.form.addrDitailAddr === "") {
      this.$toast("请填写详细收件地址");
      return false;
    }
    return true;
  }

  submit(): void {
    if (!this.formValidation()) {
      return;
    }
    let data = {
      form: this.form,
      addressName: this.addressName,
    };
    this.$router.replace({
      path: `/invoice-detail`,
      query: { orderDate: JSON.stringify(data) },
    });
  }
}
